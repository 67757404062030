class CountDown {
	constructor(seconds,count) {
		this.seconds = seconds
		this.count = count
	}

	currentSecond() {
   return this.count
	}

	getCountDown() {
		return new Promise((resolve,reject) => {
			let TIME_COUNT = this.seconds;
			let timer = null
			let show = true
			let isDisable1 = true
			if (!timer) {
				this.count = TIME_COUNT;
				show = false;
				timer = setInterval(() => {
					if (this.count > 0 && this.count <= TIME_COUNT) {
						this.count--;
					} else {
						show = true;
						clearInterval(timer);
						timer = null;
						isDisable1 = false;
						resolve(true)
					}
				}, 1000)
			}
		})
	}
}

export default CountDown