var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "RegisterMain" },
    [
      _c("img", {
        staticClass: "Register-Pic",
        attrs: { src: require("../../assets/png/jiameng.png"), alt: "" },
      }),
      _c(
        "div",
        { staticClass: "Register-Content" },
        _vm._l(_vm.cardArr, function (card, cardIndex) {
          return _c(
            "div",
            {
              key: cardIndex,
              staticClass: "Content-Card",
              on: {
                click: function ($event) {
                  return _vm.cardPoint(card)
                },
              },
            },
            [
              _c("img", { attrs: { src: card.img, alt: "" } }),
              _vm._m(0, true),
              _c("van-divider", {
                staticStyle: { "border-width": "1px", "margin-left": "0.2rem" },
                attrs: { dashed: "" },
              }),
              _c("p", { staticClass: "Fine" }, [_vm._v("在线入驻")]),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "van-action-sheet",
        {
          attrs: { title: "手机号注册" },
          model: {
            value: _vm.verificationCodeDisplay,
            callback: function ($$v) {
              _vm.verificationCodeDisplay = $$v
            },
            expression: "verificationCodeDisplay",
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "van-form",
                {
                  ref: "registrationVerification",
                  on: { failed: _vm.onFailed },
                },
                [
                  _c("van-field", {
                    attrs: {
                      label: "昵称",
                      name: "",
                      "left-icon": "manager-o",
                      placeholder: "请输入昵称",
                      rules: _vm.formRyles.nikeName,
                    },
                    model: {
                      value: _vm.ruleForm.nikeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "nikeName", $$v)
                      },
                      expression: "ruleForm.nikeName",
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      label: "手机号码",
                      "left-icon": "phone-o",
                      type: "tel",
                      placeholder: "请输入手机号码",
                      rules: _vm.formRyles.phoneNumber,
                    },
                    model: {
                      value: _vm.ruleForm.phoneNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "phoneNumber", $$v)
                      },
                      expression: "ruleForm.phoneNumber",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "Send-Verification-Code" },
                    [
                      _c("van-field", {
                        attrs: {
                          label: "验证码",
                          "left-icon": "aim",
                          type: "number",
                          name: "pattern",
                          placeholder: "验证码",
                          rules: _vm.formRyles.code,
                        },
                        model: {
                          value: _vm.ruleForm.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "code", $$v)
                          },
                          expression: "ruleForm.code",
                        },
                      }),
                      _vm.count < 1 || _vm.count === ""
                        ? _c(
                            "van-button",
                            {
                              attrs: { type: "info" },
                              on: { click: _vm.getVerificationCode },
                            },
                            [_vm._v("获取验证码 ")]
                          )
                        : _c("van-button", { attrs: { type: "info" } }, [
                            _vm._v("(" + _vm._s(_vm.count) + "s)重新获取"),
                          ]),
                    ],
                    1
                  ),
                  _c("van-field", {
                    attrs: {
                      label: "密码",
                      name: "pattern",
                      "left-icon": "bag-o",
                      type: _vm.passwordShow ? "text" : "password",
                      "right-icon": _vm.passwordShow ? "eye-o" : "closed-eye",
                      placeholder: "请输入密码",
                      rules: _vm.formRyles.password,
                    },
                    on: {
                      "click-right-icon": function ($event) {
                        _vm.passwordShow = !_vm.passwordShow
                      },
                    },
                    model: {
                      value: _vm.ruleForm.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "password", $$v)
                      },
                      expression: "ruleForm.password",
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      label: "确认密码",
                      name: "pattern",
                      type: _vm.confirmPasswordShow ? "text" : "password",
                      "right-icon": _vm.confirmPasswordShow
                        ? "eye-o"
                        : "closed-eye",
                      "left-icon": "goods-collect-o",
                      placeholder: "请输入确认密码",
                      rules: _vm.formRyles.confirmPassword,
                    },
                    on: {
                      "click-right-icon": function ($event) {
                        _vm.confirmPasswordShow = !_vm.confirmPasswordShow
                      },
                    },
                    model: {
                      value: _vm.ruleForm.confirmPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "confirmPassword", $$v)
                      },
                      expression: "ruleForm.confirmPassword",
                    },
                  }),
                ],
                1
              ),
              _c(
                "van-button",
                {
                  staticClass: "Registered-Supplier",
                  attrs: { type: "info" },
                  on: { click: _vm.verificationSupplier },
                },
                [_vm._v("注册供应商账号")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "van-popup",
        {
          attrs: { closeOnClickOverlay: true },
          model: {
            value: _vm.codeShow,
            callback: function ($$v) {
              _vm.codeShow = $$v
            },
            expression: "codeShow",
          },
        },
        [
          _c(
            "div",
            { staticClass: "line-two" },
            [
              _c("slide-verify", {
                ref: "slideblock",
                staticStyle: { margin: "0 auto" },
                attrs: {
                  l: _vm.slideObj.l,
                  r: _vm.slideObj.r,
                  w: _vm.slideObj.w,
                  h: _vm.slideObj.h,
                  imgs: _vm.imgs,
                  "slider-text": "请拖动滑块完成验证",
                },
                on: { success: _vm.onSuccess, fail: _vm.onFail },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Content-Introduce" }, [
      _c("p", [_vm._v("【携程招商平台】")]),
      _c("p", [_vm._v("开放平台,合作共赢;")]),
      _c("p", [_vm._v("精准营销,业绩分析;")]),
      _c("p", [_vm._v("领先技术,操作便捷;")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }