<template>
    <div class="RegisterMain">
        <img class="Register-Pic" src="../../assets/png/jiameng.png" alt="">
        <div class="Register-Content">
            <div class="Content-Card" v-for="(card,cardIndex) in cardArr" @click="cardPoint(card)" :key="cardIndex">
                <img :src="card.img" alt="">
                <div class="Content-Introduce">
                    <p>【携程招商平台】</p>
                    <p>开放平台,合作共赢;</p>
                    <p>精准营销,业绩分析;</p>
                    <p>领先技术,操作便捷;</p>
                </div>
                <van-divider dashed style="border-width: 1px; margin-left: 0.2rem;"></van-divider>
                <p class="Fine">在线入驻</p>
            </div>
        </div>

        <van-action-sheet v-model="verificationCodeDisplay" title="手机号注册">
            <div class="content">
                <van-form ref="registrationVerification" @failed="onFailed">
                    <van-field label="昵称" v-model="ruleForm.nikeName" name="" left-icon="manager-o" placeholder="请输入昵称"
                        :rules="formRyles.nikeName">
                    </van-field>
                    <!-- 通过 pattern 进行正则校验 -->
                    <van-field label="手机号码" v-model="ruleForm.phoneNumber" left-icon="phone-o" type="tel"
                        placeholder="请输入手机号码" :rules="formRyles.phoneNumber">
                    </van-field>
                    <!-- 发送验证码 -->
                    <div class="Send-Verification-Code">
                        <van-field label="验证码" v-model="ruleForm.code" left-icon="aim" type="number" name="pattern"
                            placeholder="验证码" :rules="formRyles.code">
                        </van-field>
                        <van-button v-if="count < 1 || count === ''" type="info" @click="getVerificationCode">获取验证码
                        </van-button>
                        <van-button v-else type="info">({{count}}s)重新获取</van-button>
                    </div>
                    <!-- 密码输入 -->
                    <van-field label="密码" v-model="ruleForm.password" name="pattern" left-icon="bag-o"
                        :type="passwordShow ? 'text' : 'password'" :right-icon="passwordShow ? 'eye-o' : 'closed-eye'"
                        @click-right-icon="passwordShow = !passwordShow" placeholder="请输入密码"
                        :rules="formRyles.password">
                    </van-field>
                    <!-- 确认密码输入 -->
                    <van-field label="确认密码" v-model="ruleForm.confirmPassword" name="pattern"
                        :type="confirmPasswordShow ? 'text' : 'password'"
                        :right-icon="confirmPasswordShow ? 'eye-o' : 'closed-eye'"
                        @click-right-icon="confirmPasswordShow = !confirmPasswordShow" left-icon="goods-collect-o"
                        placeholder="请输入确认密码" :rules="formRyles.confirmPassword">
                    </van-field>
                </van-form>
                <van-button class="Registered-Supplier" @click="verificationSupplier" type="info">注册供应商账号</van-button>
            </div>
        </van-action-sheet>

        <van-popup v-model="codeShow" :closeOnClickOverlay="true">
            <div class="line-two">
                <slide-verify :l="slideObj.l" :r="slideObj.r" :w="slideObj.w" :h="slideObj.h" :imgs=imgs
                    style="margin: 0 auto;" ref="slideblock" slider-text="请拖动滑块完成验证" @success="onSuccess"
                    @fail="onFail">
                </slide-verify>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import Api from '@/components/Api/index'
    import CountDown from '@/assets/utils/countDown'
    import {
        mapMutations
    } from 'vuex'
    export default {
        name: 'RegisterMain',
        components: {},
        // 定义属性
        data() {
            return {
                formRyles: {
                    // 手机号校验规则
                    phoneNumber: [{
                            required: true,
                            message: '请填手机号'
                        },
                        {
                            pattern: /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/,
                            message: '手机格式错误'
                        }
                    ],
                    //昵称检测
                    nikeName: [{
                            require: true,
                            message: '请填写昵称'
                        },
                        {
                            validator: (val) => {
                                if (val === '') return false
                                else return true
                            },
                            message: '昵称不能为空'
                        }
                    ],
                    //验证码检测
                    code: [{
                            require: true,
                            message: '请填写验证码'
                        },
                        {
                            validator: (val) => {
                                if (val === '') return false
                                else return true
                            },
                            message: '验证码不能为空'
                        }
                    ],
                    //密码检测
                    password: [{
                            require: true,
                            message: '请填写密码'
                        },
                        {
                            validator: (val) => {
                                if (val === '') return false
                                else return true
                            },
                            message: '密码不能为空'
                        }
                    ],
                    //确认密码检测
                    confirmPassword: [{
                            require: true,
                            message: '请再次输入密码'
                        },
                        {
                            validator: (val) => {
                                if (val === '') return false
                                else return true
                            },
                            message: '俩次输入密码不一致'
                        }
                    ]
                },
                confirmPasswordShow: false,
                passwordShow: false,
                slideObj: {
                    l: 42, //滑动碎片的大小
                    r: 10, //滑动碎片的圆角
                    w: 310, //画布的宽
                    h: 155, //画布的高
                },
                //验证码弹框
                codeShow: false,
                count: '',
                //手机注册展示弹框
                verificationCodeDisplay: false,
                ruleForm: {
                    phoneNumber: '',
                    code: '',
                    nikeName: '',
                    password: '',
                    confirmPassword: '',
                    bizId: '',
                    supplierKey: '',
                    clientKey: 0
                },
                imgs: [
                    require('../../assets/verify/1 (10).jpg'),
                    require('../../assets/verify/1 (11).jpg'),
                    require('../../assets/verify/1 (12).jpg'),
                    require('../../assets/verify/1 (13).jpg')
                ],
                cardArr: [{
                        mouseleaveShow: false,
                        img: require('../../assets/png/lxs11.png'),
                        key: 'a0726839e56dcd7c25977e82a15486ec'
                    },
                    {
                        mouseleaveShow: false,
                        img: require('../../assets/png/zy.png'),
                        key: '7524fb6196f966e352ffa4d3a50fefff'
                    },
                    {
                        mouseleaveShow: false,
                        img: require('../../assets/png/person.png'),
                        key: 'd09996d94d4823d04ae10be5c10d85ce'
                    }
                ]
            }
        },
        // 计算属性，会监听依赖属性值随之变化
        computed: {},
        // 监控data中的数据变化
        watch: {},
        // 方法集合
        methods: {
            ...mapMutations('supplier', ['setAccessToken', 'SET_KEY']),
            cardPoint(card) {
                this.verificationCodeDisplay = true
                this.ruleForm = {
                    phoneNumber: '',
                    code: '',
                    nikeName: '',
                    password: '',
                    confirmPassword: '',
                    bizId: '',
                    supplierKey: '',
                    clientKey: 0
                }
                this.ruleForm.supplierKey = card.key
            },

            getVerificationCode() {
                this.codeShow = true
                this.$nextTick(() => {
                    this.$refs.slideblock.reset();
                })
            },

            //手机验证失效回调
            onFailed(e) {},
            //滑块位置正确回调
            onSuccess() {
                this.codeShow = false
                let _data = new CountDown(60)
                _data.getCountDown();
                setInterval(() => {
                    this.count = _data.currentSecond()
                }, 1000)
                //发送验证码
                new Api("oauth").sendRegisterCode(this.ruleForm.phoneNumber).then((result) => {
                    if (result.status === 200 && result.data !== '') {
                        this.ruleForm.bizId = result.data
                        this.$nextTick(() => {
                            this.$refs.slideblock.reset();
                        })
                    }
                })
            },
            verificationSupplier() {
                this.$refs.registrationVerification.validate().then(() => {
                    new Api("oauth").verificationCode(this.ruleForm).then(res => {
                        if (res.status === 200 && res.data !== '' && res.data != null) {
                            this.$notify({
                                type: 'success',
                                message: '恭喜您！成功注册供应商...'
                            });
                            new Api("oauth").h5ExchangeForToken(res.data).then(res => {
                                if (res.status === 200) {
                                    //设置Token (只持久化Token 如果俩小时内没注册完成,则重新走流程)
                                    this.setAccessToken(res.data.accessToken);
                                    //设置供应商的KEY值
                                    this.SET_KEY(this.ruleForm.supplierKey);
                                    //跳转选择
                                    this.$router.push({
                                        name: 'SupplierType'
                                    })
                                }
                            })
                        } else {
                            this.$notify({
                                type: 'warning',
                                message: res.statusText
                            });
                        }
                    })
                }).catch(() => {})
            },
            //滑块位置错误回调
            onFail() {}
        },
        // 生命周期 - 创建完成（可以访问当前this实例）
        created() {},
        // 生命周期 - 挂载完成（可以访问DOM元素）
        mounted() {
            let clientWidth = document.documentElement.clientWidth; //获取HTML文档所在窗口的当前宽度
            let ratio = (clientWidth / 750) * 40; //计算基数为40的比例,然后动态计算画布在当前窗口的宽高，撑开弹窗
            this.slideObj.l = 1.2 * ratio;
            this.slideObj.r = 0.3 * ratio;
            this.slideObj.w = 12.6 * ratio;
            this.slideObj.h = 7.8 * ratio;
        },
        beforeCreate() {}, // 生命周期 - 创建之前
        beforeMount() {}, // 生命周期 - 挂载之前
        beforeUpdate() {}, // 生命周期 - 更新之前
        updated() {}, // 生命周期 - 更新之后
        beforeDestroy() {}, // 生命周期 - 销毁之前
        destroyed() {}, // 生命周期 - 销毁完成
        activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>

<style scoped lang="scss">
    .Register-Pic {
        width: 100%;
        height: 3rem;
    }

    .Register-Content {
        .Content-Card {
            background-color: white;
            margin-bottom: 0.2rem;
            display: flex;

            .Fine {
                display: flex;
                align-items: center;
                font-size: 0.28rem;
                text-align: center;
                color: #DB4E1A;
                margin: 0 auto;
            }

            >img {
                width: 3rem;
                height: 2rem;
            }

            .Content-Introduce {
                color: #B3B3B3;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 0.4rem;
                font-size: 0.24rem;
            }
        }
    }

    .Send-Verification-Code {
        display: flex;

        /deep/ .van-button--info {
            width: 4rem;
            height: 0.6rem;
            display: flex;
            margin-top: 0.15rem;
            margin-right: 0.5rem;
            background-color: #DB4E1A;
            border: none !important;
            justify-content: center;
        }
    }

    .Registered-Supplier {
        width: 90%;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        /* margin: 0 auto; */
        /* text-align: center; */
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        background-color: #DB4E1A;
        border: none !important;
    }

    .line-two {
        padding: 0.1rem;
        background: white;
    }
</style>